/* Fonts */

$font-family-base: 'Roboto', sans-serif;
$font-size-base: 16px;
$line-height-base: 25px;
$font-size-base-mobile: 15px;
$line-height-base-mobile: 23px;
$font-weight-base: 400;
$font-weight-bolder: 500;
$font-weight-boldest: 900;

/* Colors */

$black: #000000;
$white: #FFFFFF;

$color1: #7f8429;
$color1darkened: #56591c;
$color2: #2F736C;
$color3: #C5D9D9;;
$color4: #183459;
$colorText: #333333;
$colorText1: #C4C4C4;
$colorBackground: $white;
$colorBackgroundLight: #F2F2F2;
$colorBorder: rgba(0, 0, 0, 0.1);
$colorButtonHover: $colorText;
$colorTextHover: $white;
$colorButtonOutlineHover: $color1;
$colorButtonOutlineTextHover: $color1;
$colorTextLinkHover: $color1;

$colors: (
        color1: $color1,
        color2: $color2,
        color3: $color3,
        color4: $color4,
        colorText: $colorText,
        colorText1: $colorText1,
        colorBackgroundLight: $colorBackgroundLight,
        colorBorder: $colorBorder,
        colorButtonHover: $colorButtonHover,
        colorTextHover: $colorTextHover,
        colorButtonOutlineHover: $colorButtonOutlineHover,
        colorButtonOutlineTextHover: $colorButtonOutlineTextHover,
        colorTextLinkHover: $colorTextLinkHover
) !default;


$pink: $color1;
$black: rgba(0,0,0,1);
$white: rgba(255,255,255,1);
$grey: rgba(236,237,237,1);
$grey-dark: rgba(197,198,200,1);
$link-color: $color1;
$link-hover-color: $color2;

$link-decoration: none;
$link-hover-decoration: none;

/* Table */

$table-cell-padding: 10px;
$table-caption-color: $black;

/* Special Margins */

$headings-margin-bottom: 0;
$paragraph-margin-bottom: 15px;
$label-margin-bottom: 10px;

/* Page Margins */

$pageMarginMobile: 15px;
$pageMarginDesktop: 28px;

/* Breakpoints */
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        mdl: 880px,
        lg: 992px,
        lgl: 1096px,
        xl: 1200px,
        xll: 1385px,
        xlplus: 1630px,
        outer: 1920px
) !default;
$maxWidthDesktop: map-get($grid-breakpoints, xl);
$maxWidthDesktopPlus: map-get($grid-breakpoints, xlplus);
$outerLimit: map-get($grid-breakpoints, outer);

/* Page Layout Elements */

$headerHeightMobile: 120vw;
$headerHeightDesktopPortal: 100vh;
$headerHeightDesktopContent: 50vh;

/* Container Paddings & Margins */

$containerMarginMobile: 15px;
$containerMarginDesktop: 30px;