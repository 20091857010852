/* Version 2 */

@include media-breakpoint-down(sm) {
  .page-header--1 {
    &:after {
      content: '';
      width: 100%;
      height: pxToVwMobile(157px);
      background: $white;
      position: absolute;
      top: 0;
      left: 0;
    }

    .page-header--logo .logo {
      background-image: svg-load('../_images/svg/logo-black.svg');
    }

    .page-header--contact a {
      color: $colorText;
    }

    .main-nav--burger {
      span {
        background: $colorText;

        &:before,
        &:after {
          background: $colorText;
        }
      }
    }

    .page-header--image {
      img {
        height: calc(100% - #{pxToVwMobile(157px)});
        top: auto;
        bottom: 0;
      }

      &:after {
        height: 100%;
      }
    }
  }
}

@include media-breakpoint-between(md, xl) {
  .page-header--1 {
    .main-nav--burger {
      span {
        background: $colorText;

        &:before,
        &:after {
          background: $colorText;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .page-header--1 {
    &:after {
      content: '';
      width: 100%;
      height: 200px;
      background: $white;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 51;
    }
    .page-header--meta {
      border-bottom: 1px solid rgba($colorText, .2);

      .page-header--contact a,
      .page-header--social a,
      .page-header--language a {
        color: $colorText;
      }
    }
    .page-header--language li a {
      border-left: 1px solid rgba($colorText, .2);
    }
  }
}

@include media-breakpoint-up(xll) {
  .page-header--1 {
    ul.main-nav--first-level > li > a {
      color: $colorText;
    }

    .main-nav ul.main-nav--first-level > li > a.btn {
      border-color: $colorText;
      color: $colorText;

      &:hover {
        border-color: $color1;
        background: $color1;
        color: $white;
      }
    }
  }
}