.contentblock--textbox {
  .textbox {
    background: $color1;
    color: $white;
  }
  .textbox--image {
    position: relative;
    height: 100%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .textbox--content-container {
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
    }
    padding-top: pxToVwMobile(40px);
    padding-right: pxToVwMobile(35.73px);
    padding-bottom: pxToVwMobile(40px);
    padding-left: pxToVwMobile(35.73px);
    @include media-breakpoint-up(md) {
      padding: 10% 10% 16% 10%;
    }
  }
  h2, h3 {
    font-weight: $font-weight-boldest;
    margin-bottom: pxToVwMobile(20px);
    @include media-breakpoint-up(md) {
      margin-bottom: 5%;
    }
  }
  h3 {
    @include fluidDiff(font-size, 22px, 31px);
    @include fluidDiff(line-height, 26px, 36px);
  }
  p {
    margin-bottom: pxToVwMobile(20px);
    @include media-breakpoint-up(md) {
      margin-bottom: 5%;
    }
  }
  a.btn--outline-negative {
    &:hover {
      border-color: $white;
      background: $white;
      color: $color1;
    }
  }
}

@include media-breakpoint-up(md) {
  .textbox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    &.textbox--image-right {
      direction: rtl;
      & * {
        direction: ltr;
      }
    }
  }
}