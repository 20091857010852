// standard

$buttonHeight: 60px;
$buttonHeightFlat: 40px; // is also mobile height
$buttonBorderWidth: 2px;

$buttonLineHeight: $buttonHeight - (2 * $buttonBorderWidth);
$buttonLineHeightFlat: $buttonHeightFlat - (2 * $buttonBorderWidth);

.btn {
  display: inline-block;
  background: $color1;
  color: $white;
  border-style: solid;
  border-color: $color1;
  @include fluid(border-width, $buttonBorderWidth);
  text-transform: uppercase;
  font-weight: $font-weight-bolder;
  @include fluidDiff(line-height, $buttonLineHeightFlat, $buttonLineHeight);
  @include fluidDiff(font-size, 13px, 15px);
  @include fluid(border-radius, 30px);
  @include fluidDiff(padding-left, 25px, 30px);
  @include fluidDiff(padding-right, 25px, 30px);
  transition:
          box-shadow ease-in-out .2s,
          background ease-in-out .2s,
          border-color ease-in-out .2s,
          color ease-in-out .2s;

  &:hover {
    background: $black;
    color: $white;
    border-color: $black;
  }

  // flat

  &.btn--flat {
    @include fluidDiff(line-height, $buttonLineHeightFlat, $buttonLineHeightFlat);
  }

  // outline

  &.btn--outline {
    background: transparent;
    border-color: $black;
    color: $black;
    box-shadow: 0 0 60px rgba(0,0,0,0);

    &:hover {
      border-color: $white;
      color: $color1;
      box-shadow: 0 0 60px rgba(0,0,0,0.1);
    }

  }

  // outline negative

  &.btn--outline-negative {
    background: transparent;
    border-color: $white;
    color: $white;

    @include media-breakpoint-up(xll) {
      &:hover {
        border-color: $color1;
        color: $color1;
      }
    }

  }

  &.btn--outline-negative--variant {
    background: $white;
    border-color: $white;
    color: $color1;
  }

}

// arrow-link

a {
  &.arrow-link {
    color: $colorText;
    i {
      @include fluid(font-size, 12px);
      @include fluid(margin-right, 15px);
      color: $colorBorder;
      transition: color ease-in-out .3s;
    }
    &:hover {
      i {
        color: $color1;
        opacity: 1;
      }
    }
  }
}

// arrows

.prev, .next {
  $buttonSize: 45px;
  display: inline-grid;
  @include fluid(width, $buttonSize);
  @include fluid(height, $buttonSize);
  @include fluid(border-radius, $buttonSize);
  background: $color1;
  cursor: pointer;
  place-items: center;
}
.prev--arrow {
  @include css-arrow($white, left, pxToVwMobile(10px), pxToVwMobile(2px));
  @include fluid(margin-left, 3px);
  @include media-breakpoint-up(md) {
    @include css-arrow($white, left, 10px, 2px);
  }
}
.next--arrow {
  @include css-arrow($white, right, pxToVwMobile(10px), pxToVwMobile(2px));
  @include fluid(margin-left, -3px);
  @include media-breakpoint-up(md) {
    @include css-arrow($white, right, 10px, 2px);
  }
}