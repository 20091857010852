/*
.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  background: $color1;
  border-color: $color1;
}
.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
  fill: $color1;
}*/


/**
 * @package PickMeUp
 * @author  Nazar Mokrynskyi <nazar@mokrynskyi.com>
 * @license 0BSD
 */
$border-radius                    : .4em !default;
$background                       : $white !default;
$color                            : $colorText !default;
$background-hover                 : transparent !default;
$color-hover                      : $color1 !default;
$nav-color                        : $color1 !default;
$nav-color-hover                  : $color-hover !default;
$not-in-month                     : rgba($colorText, .4) !default;
$not-in-month-hover               : $color1 !default;
$disabled                         : $colorBackgroundLight !default;
$selected                         : $white !default;
$selected-background              : $color1 !default;
$not-in-month-selected-background : $color1 !default;
$day-of-week                      : $not-in-month-hover !default;
$today-background                 : $color2 !default;
$today-color                      : $white !default;

.pickmeup {
  background      : $background;
  border-radius   : $border-radius;
  -moz-box-sizing : content-box;
  box-sizing      : content-box;
  display         : inline-block;
  position        : absolute;
  touch-action    : manipulation;
  z-index: 999;
  box-shadow: 0 0 60px rgba(0,0,0,.1);

  * {
    -moz-box-sizing : border-box;
    box-sizing      : border-box;
  }

  &.pmu-flat {
    position : relative;
  }

  &.pmu-hidden {
    display : none;
  }

  .pmu-instance {
    display    : inline-block;
    height     : 13.8em;
    padding    : .5em;
    text-align : center;
    width      : 15em;

    .pmu-button {
      color           : $color;
      cursor          : pointer;
      outline         : none;
      text-decoration : none;
    }

    .pmu-today {
      background : $today-background;
      color      : $today-color;
    }

    .pmu-button:hover {
      background : $background-hover;
      color      : $color-hover;
    }

    .pmu-not-in-month {
      color : $not-in-month;
    }

    .pmu-disabled,
    .pmu-disabled:hover {
      color  : $disabled;
      cursor : default;
    }

    .pmu-selected {
      background : $selected-background;
      color      : $selected;
      &:hover {
        color: $white;
        background: $color1;
      }
    }

    .pmu-not-in-month.pmu-selected {
      background : $not-in-month-selected-background;
    }

    nav {
      color       : $nav-color;
      display     : -ms-flexbox;
      display     : -webkit-flex;
      display     : flex;
      line-height : 2em;

      *:first-child :hover {
        color : $nav-color-hover;
      }

      .pmu-prev,
      .pmu-next {
        display : none;
        height  : 2em;
        width   : 1em;
      }
      .pmu-month {
        width : 14em;
      }
    }

    .pmu-years,
    .pmu-months {
      * {
        display     : inline-block;
        line-height : 3.6em;
        width       : 3.5em;
      }
    }

    .pmu-day-of-week {
      color  : $day-of-week;
      cursor : default;
    }

    .pmu-day-of-week,
    .pmu-days {
      * {
        display     : inline-block;
        line-height : 1.5em;
        width       : 2em;
      }
    }

    .pmu-day-of-week * {
      line-height : 1.8em;
    }
  }

  .pmu-instance:first-child .pmu-prev,
  .pmu-instance:last-child .pmu-next {
    display : block;
  }

  .pmu-instance:first-child,
  .pmu-instance:last-child {
    .pmu-month {
      width : 13em;
    }
  }

  .pmu-instance:first-child:last-child .pmu-month {
    width : 12em;
  }

  &:not(.pmu-view-days) .pmu-days,
  &:not(.pmu-view-days) .pmu-day-of-week,
  &:not(.pmu-view-months) .pmu-months,
  &:not(.pmu-view-years) .pmu-years {
    display : none;
  }
}
