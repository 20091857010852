.page-header--cta {
  width: 100%;
  align-self: flex-end;
  z-index: 1;
  text-align: center;
  padding-left: pxToVwMobile(30px);
  padding-right: pxToVwMobile(30px);
  white-space: nowrap;

  h3 {
    font-size: pxToVwMobile(14px);
    line-height: pxToVwMobile(16px);
    text-transform: uppercase;
    margin-bottom: pxToVwMobile(10px);
    font-weight: $font-weight-base;
    font-style: italic;
    color: $white;
  }

  h2 {
    font-size: pxToVwMobile(45px);
    line-height: pxToVwMobile(40px);
    margin-bottom: pxToVwMobile(15px);
    text-transform: uppercase;
    font-weight: $font-weight-boldest;
    color: $white;
  }
}

@include media-breakpoint-up(md) {
  .page-header--cta {
    padding: 0;
    h3 {
      @include fluidPlus(font-size, 20px);
      @include fluidPlus(line-height, 23px);
      @include fluidPlus(padding-left, 40px);
      margin-bottom: 0;
      position: relative;

      &:before {
        content: '';
        @include fluidPlus(width, 30px);
        @include fluidPlus(height, 2px);
        background: $white;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }

    h2 {
      @include fluidPlus(font-size, 160px);
      @include fluidPlus(line-height, 140px);
      @include fluid(margin-bottom, 20px);
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }

  /* Animations */

  .page-header--cta {
    & > * {
      &:nth-child(1) {
        animation: 1s ease-out 0s 1 animationOne;
      }
      &:nth-child(2) {
        animation: 1s ease-out 0s 1 animationTwo;
      }
      &:nth-child(3) {
        transition: transform ease-out 1s,
        background ease-in-out .2s,
        border-color ease-in-out .2s,
        color ease-in-out .2s;
        animation: 1s ease-out 0s 1 animationThree;
      }
    }
  }

  @keyframes animationOne {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes animationTwo {
    0% {
      opacity: 0;
      transform: translateX(75px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes animationThree {
    0% {
      opacity: 0;
      transform: translateX(125px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

}