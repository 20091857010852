@import 'variables';
@import 'mixins';

/* Variables calculated after both files */



/* libraries */
@import '../.build/node_modules/tiny-slider/src/tiny-slider';
@import '../.build/node_modules/baguettebox.js/src/baguetteBox';


/* basics */
@import 'reboot';
@import 'fonts';
@import 'icons';
@import 'typography';
@import 'helpers';
@import 'base';
@import "consent"; /* Cookie Consent*/
@import "project_customizations"; /* Contains Customizations for the project */

/* elements */
@import 'elements/datepicker';
@import 'elements/form';
@import 'elements/buttons';
@import 'elements/teaser';
@import 'elements/teaser--small';
@import 'elements/teaserslider-controls';
@import 'elements/logos';

/* page-header */
@import 'page-header/page-header';

@import 'page-header/page-header--meta';
@import 'page-header/page-header--boxed';
@import 'page-header/page-header--cta';
@import 'page-header/page-header--image';

/* page-header widgets */
@import 'page-header/page-header--teaserslider';
@import 'page-header/page-header--smallteasers';
@import 'page-header/page-header--booking';
@import 'page-header/page-header--nav';
@import 'page-header/page-header--nav-fixed';

/* page-header versions */
@import 'page-header/page-header--versions';

/* page-footer */
@import 'page-footer/footer';

/* content-elements elements */
@import 'content-elements/headlines';
@import 'content-elements/accordion';
@import 'content-elements/largeteaser';
@import 'content-elements/teaserslider';
@import 'content-elements/textbox';
@import 'content-elements/wysiwyg';
@import 'content-elements/gallery';
@import 'content-elements/table';
@import 'content-elements/grid';
@import 'content-elements/divider';
@import 'content-elements/disruptor';

@include media-breakpoint-down(sm) {
  .mobile-hidden {
    display: none !important;
  }
}
@include media-breakpoint-down(xl) {
  .desktop-down-hidden {
    display: none !important;
  }
}