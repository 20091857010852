.page-header--image {

  width: 100vw;
  @include fluidDiff(height, 450px, $headerHeightDesktopContent);

  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    @include fluid(height, 125px);
    background: linear-gradient(180deg, rgba(24, 52, 89, 0) 0%, rgba(24, 52, 89, 0.4) 100%);
    bottom: 0;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}


@include media-breakpoint-up(md) {
  .page-header--image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    &:after {
      @include fluid(height, 300px);
    }
  }

}