.teaser--small {
  display: grid;
  grid-template-columns: pxToVwMobile(107px) auto;
  @include media-breakpoint-up(md) {
    grid-template-columns: 107px auto;
  }
  .teaser--image {
    padding-bottom: 0;
    height: 100%;
    img {
      height: 100%;
    }
  }
}
@include media-breakpoint-up(md) {
  .teaser--small {
    //grid-template-columns: pxToVw(107px) auto;
    @include media-breakpoint-up(xl) {
      //grid-template-columns: 107px auto;
    }
  }
}