/* Teaserslider Controls */

.teaserslider {
  position: relative;
}
.teaserslider--controls {
  .prev,
  .next {
    position: absolute;
    @include fluid(top, 90px);
  }
  .prev {
    @include fluid(left, -20px);
  }
  .next {
    @include fluid(right, -20px);
  }
}