.contentblock--largeteaser {
  .largeteaser {
    @include fluidDiff(margin-left, $containerMarginMobile * -1, 0);
    @include fluidDiff(margin-right, $containerMarginMobile * -1, 0);
    display: block;

    span {
      display: block;
    }

    h3 {
      @include fluidDiff(font-size, 18px, 25px);
      @include fluidDiff(line-height, 21px, 29px);
      @include fluidDiff(margin-bottom, 20px, 32px);
    }

    .arrow-link {
      position: absolute;
      @include fluidDiff(bottom, 27.31px, 67.17px);
      @include fluidDiff(right, 30px, 60px);
      i {
        @include fluid(margin-right, 10px);
      }
    }

    .arrow-right {

      @include fluidDiff(width, 60px, 100px);
      @include fluidDiff(height, 60px, 100px);
      @include fluidDiff(left, -30px, -50px);
      @include fluidDiff(top, 30px, 60px);
      background: $color1;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        @include fluidDiff(font-size, 17px, 26px);
        color: $white;
      }
    }
  }
}

.largeteaser--image {
  background: $color2;
  img {
    width: 100%;
    height: auto;
  }
}

.largeteaser--content {
  @include fluidDiff(margin-top, -60px, -80px);
  @include fluidDiff(padding-left, 45px, 80px);
  @include fluidDiff(padding-right, 25px, 25px);
  position: relative;
  z-index: 1;
}

.largeteaser--content-container {
  position: relative;
  background: $white;
  @include fluid(border-radius, 20px);
  color: $black;

  @include fluidDiff(padding-top, 30px, 96px);
  @include fluidDiff(padding-right, 30px, 60px);
  @include fluidDiff(padding-bottom, 68.31px, 122.17px);
  @include fluidDiff(padding-left, 60px, 103px);
  /*
    padding-top: 60px;
    padding-right: 60px;
    padding-bottom: 100px;
    padding-left: 90px;
   */
}

@include media-breakpoint-up(md) {
  .contentblock--largeteaser {


    .largeteaser {
      display: grid;
      @include media-breakpoint-up(xl) {
        grid-template-columns: 82fr 18fr;
      }
      &.largeteaser--image-right {
        direction: rtl;
        .largeteaser--content {
          margin-left: auto;
          @include media-breakpoint-up(xl) {
            @include fluidPlus(margin-right, -175px);
            margin-right: -175px;
          }
        }
        .largeteaser--content-container {
          @include fluidPlus(padding-left, 60px);
          @include fluidPlus(padding-right, 103px);
          direction: ltr;
        }
        .arrow-right {
          left: auto;
          @include fluidPlus(right, -50px);
        }
      }
    }



    .largeteaser--content {
      margin-top: -80px;
      padding-left: 80px;
      padding-right: 25px;
      @include media-breakpoint-up(xl) {
        @include fluidPlus(margin-left, -175px);
        margin-top: 0;
        margin-left: -175px;
        padding: 0;
      }


      display: flex;
      align-items: center;
      justify-content: center;
    }


  }
}