/* Tabelle */

.contentblock--table-responsive {
  width: 100%;
  overflow-x: auto;
}
.contentblock--table {
  font-size: 16px;
  line-height: 25px;
  table {
    width: 100%;
    border: none;
  }
  th {
    background: $color1;
    color: $white;
    border: none;
  }
  th, td {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
  }
  td {
    border-bottom: 1px solid rgba(0,0,0,.1);
  }
}