.page-header--smallteasers {
  display: grid;
  @include fluid(grid-row-gap, 10px);
  .teaser--small {
    h3 {
      font-weight: $font-weight-boldest;
    }
  }
  @include media-breakpoint-up(md) {
    align-self: flex-end;
  }
}
