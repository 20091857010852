.page-header--meta {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include fluidDiff(padding-top, 15px, 0);
  @include fluidDiff(padding-bottom, 15px, 0);
  @include fluid(padding-left, 20px);
  @include fluid(font-size, 14px);
  @include fluidDiff(line-height, $metaHeight, $metaHeightDesktop);
}

// Special styling only for mobile

@include media-breakpoint-down(sm) {
  .page-header--meta {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
  }
}

// Styles for all lists in meta area

.page-header--contact,
.page-header--social,
.page-header--language {

  @include fluidDiff(height, $metaHeight, $metaHeightDesktop);
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-block;


  li {
    display: inline-block;
  }

  a {
    color: $white;
    @include fluidDiff(line-height, $metaHeight, $metaHeightDesktop);
    display: inline-block;
    float: left;
    i {
      @include fluidDiff(line-height, $metaHeight, $metaHeightDesktop);
      display: inline-block;
      float: left;

      &:before {
        @include fluid(font-size, 20px);
        display: inline-block;
        float: left;
      }
    }
  }
}

// Special Styles only for left column

.page-header--meta-left {
  i {
    margin-right: pxToVwMobile(5px);
    @include fluidDiff(margin-right, 5px, 10px);
  }
}

// MD Up

@include media-breakpoint-up(md) {

  .page-header--meta {
    border-bottom: 1px solid rgba($white, .4);
  }

  // Lists

  .page-header--meta-left,
  .page-header--meta-right {
    height: $metaHeightDesktop;
  }

  .page-header--meta-left {
    li {
      margin-right: 20px;
    }
  }
  .page-header--meta-right {
    text-align: right;
  }

  // Social Icons

  .page-header--social {
    li {
      float: left;
      a {
        text-align: center;
        i {
          &:before {
            width: 46px;
          }
        }
      }
    }
  }

  // Language Links

  .page-header--language {
    float: right;
    li {
      a {
        border-left: 1px solid rgba($white, .4);
        width: $metaHeightDesktop;
        text-align: center;
      }
    }
  }

}