$teaserImageWidth: 370;
$teaserImageHeight: 208;
$ratioTeaser: ($teaserImageHeight*100)/$teaserImageWidth * 1%;

$headerTeaserImageWidth: 305;
$headerTeaserImageHeight: 178.69;
$headerTeaserRatio: ($headerTeaserImageHeight*100)/$headerTeaserImageWidth * 1%;

$teaserEasing: ease-in-out;
$teaserTransitionTime: .3s;

.contentblock .teaser,
.teaser {
  display: block;
  @include fluid(border-radius, 20px);
  background: $white;
  overflow: hidden;
  position: relative;
  @include fluid(max-width, 370px);
  background: $white;
  transition: background $teaserEasing $teaserTransitionTime;
  h3 {
    @include fluid(font-size, 25px);
    @include fluid(line-height, 29px);
    @include fluid(margin-bottom, 25px);
  }
  .arrow-right {
    position: absolute;
    @include fluid(width, 16px);
    @include fluid(height, 16px);
    @include fluid(right, 30px);
    @include fluid(bottom, 30px);
    color: $colorBorder;
    transition: color $teaserEasing $teaserTransitionTime;
    i {
      @include fluid(font-size, 17px);
    }
  }
  h3, p {
    color: $black;
    transition: color $teaserEasing $teaserTransitionTime;
  }
  &:hover {
    background: $color1;
    .arrow-right {
      color: $white;
    }
    h3, p {
      color: $white;
    }
  }
}

.teaser--image {
  width: 100%;
  height: 0;
  padding-bottom: $ratioTeaser;
  //padding-bottom: $headerTeaserRatio;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.teaser--content-container {
  color: $black;
  @include fluid(padding-top, 40px);
  @include fluid(padding-right, 40px);
  @include fluid(padding-bottom, 60px);
  @include fluid(padding-left, 40px);
}

