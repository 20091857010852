/* Hide cookie box bottom left */
#tarteaucitronRoot #tarteaucitronAlertSmall {
  display: none !important;
}
#tarteaucitronInfo{
  color: black !important;
  text-align: left !important;
}
.tarteaucitronH1 {
  color: black !important;
}
.tarteaucitronMainLine,#tarteaucitronInfo{
  background: white !important;
}
#tarteaucitronMainLineOffset #tarteaucitronInfo {
  text-align: justify !important;
}
#tarteaucitronMainLineOffset #tarteaucitronPrivacyUrlDialog{
  display: none !important;
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert {
  text-align: justify !important;
}
#tarteaucitronAlertBig #tarteaucitronPrivacyUrl {
  display: none !important;
  color: black !important;
}
#tarteaucitron .tarteaucitronBorder {
  border: none !important;
}
#tarteaucitronInfo {
  text-align: left !important;
}

.tarteaucitronLine {
  border: none !important;
}
#tarteaucitronClosePanel {
  width: 3% !important;
}

.tarteaucitronBeforeVisible  .tarteaucitronAllow ,
.tarteaucitronBeforeVisible  .tarteaucitronIsSelected ,
.tarteaucitronBeforeVisible  .tarteaucitronDeny {
  border-radius: 30px !important;
  display: inline-block !important;
  background: #69bfac !important;
  color: #fff !important;
  border: .53333vw solid #69bfac !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;

}
#tarteaucitronAlertBig{
  background: white !important;
  border-radius: 20px !important;
}

#tarteaucitronDisclaimerAlert {
  color: black !important;
  text-align: left !important;
}

#tarteaucitronServices #tarteaucitronMainLineOffset {
  -webkit-border-top-left-radius: 20px;
  -webkit-border-top-right-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-topright: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.tarteaucitronBorder{
  -webkit-border-bottom-right-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-bottomright: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

#tarteaucitronPersonalize {
  border-radius: 30px  !important;
  display: inline-block !important;
  background: #69bfac !important;
  color: #fff !important;
  border: .53333vw solid #69bfac !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
}

