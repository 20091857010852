/* Version 3 */

@include media-breakpoint-down(sm) {
  .page-header--2 {
    &:after {
      content: '';
      width: 100%;
      height: pxToVwMobile(50px);
      background: $white;
      position: absolute;
      top: 0;
      left: 0;
    }

    .page-header--logo .logo {
      background-image: svg-load('../_images/svg/logo-white.svg');
    }

    .page-header--contact a {
      color: $colorText;
    }

    .main-nav--burger {
      span {
        background: $colorText;

        &:before,
        &:after {
          background: $colorText;
        }
      }
    }

    .page-header--image {
      img {
        height: calc(100% - #{pxToVwMobile(50px)});
        top: auto;
        bottom: 0;
      }

      &:after {
        height: 100%;
      }
    }
  }
}

/*@include media-breakpoint-between(md, xl) {
  .page-header--version3 {
    .main-nav--burger {
      span {
        background: $colorText;

        &:before,
        &:after {
          background: $colorText;
        }
      }
    }
  }
}*/

@include media-breakpoint-up(md) {
  .page-header--2  {
    &:after {
      content: '';
      width: 100%;
      height: 49px;
      background: $white;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 51;
    }
    .page-header--meta {
      border-bottom: 1px solid rgba($colorText, .2);

      .page-header--contact a,
      .page-header--social a,
      .page-header--language a {
        color: $colorText;
      }
    }
    .page-header--language li a {
      border-left: 1px solid rgba($colorText, .2);
    }

    .page-header--boxed {
      grid-template-areas:
              'logo nav'
              'widgets widgets';
    }
    .page-header--cta {
      position: absolute;
      bottom: 280px;
    }

    /* Booking Widget */

    .page-header--widgets {
      padding: 0;
    }

    .page-header--booking {
      @include fluid(max-width, 1220px);
      margin: 0 auto;
      padding: 0;
      form {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(3, auto);
        //grid-template-columns: pxToVw(400px) pxToVw(625px) 1fr;
        /*@include media-breakpoint-up(xl) {
          grid-template-columns: 400px 625px 1fr;
        }*/
      }
    }
    .booking-dates {
      border-bottom: none;
      border-right: 1px solid rgba(0,0,0,.1);
      @include fluid(grid-column-gap, 30px);
      @include fluid(padding-top, 30px);
      @include fluid(padding-left, 30px);
      @include fluid(padding-right, 30px);
      @include fluid(padding-bottom, 30px);
      padding-bottom: 0;
    }
    .booking-numbers {
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .form-element.booking-element--numbers {
      display: block;
      border-right: 1px solid rgba(0,0,0,.1);
      @include fluid(padding-top, 30px);
      @include fluid(padding-right, 30px);
      @include fluid(padding-left, 30px);
      padding-bottom: 0;
      label {
        @include fluid(line-height, 14px);
        @include fluid(margin-bottom, 7px);
      }
    }

    .booking-button {
      @include fluid(padding-bottom, 30px);
      display: flex;
      .btn {
        align-self: flex-end;
      }
    }


  }
}

/*
@include media-breakpoint-up(xll) {
  .page-header--version3 {
    ul.main-nav--first-level > li > a {
      color: $colorText;
    }

    .main-nav ul.main-nav--first-level > li > a.btn {
      border-color: $colorText;
      color: $colorText;

      &:hover {
        border-color: $color1;
        background: $color1;
        color: $white;
      }
    }
  }
}*/
