.page-header--widgets {
  .teaser--content-container {
    @include fluidDiff(padding-top, 18.18px, 20px);
    @include fluid(padding--left, 20px);
    @include fluidDiff(padding-right, 50px, 60px);
    @include fluidDiff(padding-bottom, 16.84px, 19px);
  }

  .teaser {
    h3 {
      @include fluidDiff(min-height, 32px, 46px);
      @include fluidDiff(font-size, 16px, 20px);
      @include fluidDiff(line-height, 19px, 23px);
      margin: 0;
      font-weight: $font-weight-bolder;
    }
    .arrow-right {
      @include fluid(right, 20px);
      @include fluid(bottom, 20px);
    }
  }

  .tns-outer {
    box-shadow: 0 0 pxToVwMobile(60px) $colorBorder;
    @include media-breakpoint-up(md) {
      box-shadow: 0 0 60px $colorBorder;
    }
    @include fluid(border-radius, 20px);
    overflow: hidden;
  }

  .teaser:not(.teaser--small) {
    box-shadow: none;
  }



  @include media-breakpoint-up(md) {
      .teaserslider {
        max-width: 370px;
        align-self: flex-end;
      }
      .teaserslider--controls {
        .prev,
        .next {
          top: 90px;
        }
      }

  }
}
