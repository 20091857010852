// Icons
$icon-path: '../_images/svg/';
$icon-list: (
        arrow-right,
        calendar,
        envelope,
        facebook,
        instagram,
        twitter,
        youtube
);
.icon,
.inline-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1em;
}
.icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}