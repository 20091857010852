$logoWidth: 197px;
$logoHeight: 80px;
$logoRatio: (strip-unit($logoHeight)*100)/strip-unit($logoWidth);

.logo {
  background-image: svg-load('../_images/svg/logo-white.svg');
  width: 100%;
  height: 0;
  padding-bottom: $logoRatio + 0%;
  display: block;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-indent: -9999em;
}
.logo--black {
  background-image: svg-load('../_images/svg/logo-black.svg');
}
.logo--flat {
  display: inline-block;
  @include fluid(width, 97px);
  @include fluid(height, 8px);
  position: relative;
  color: rgba($black, .4);
  transition: color ease-in-out .3s;
  i {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include fluid(font-size, 97px);
    @include fluid(line-height, 8px);
  }
  &:hover {
    color: rgba($black, 1);
  }
}


