$formWidth: 500px;
$inputWidth: 380px;

input[type=email],
input[type=text],
input[type=date],
input[type=password],
select,
textarea {
  width: 100%;
  border: 1px solid $colorBorder;
  @include fluid(height, 40px);
  @include fluid(line-height, 40px);
  @include fluid(border-radius, 5px);
  @include fluid(font-size, 14px);
  @include fluid(padding-left, 15px);
  @include fluid(padding-right, 15px);
  padding-top: 0;
  padding-bottom: 0;

  &:focus {
    outline: none;
    border-color: $color1;
  }

}

.form-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }
}

.form-element {
  @include fluid(margin-bottom, 20px);
  label {
    text-transform: uppercase;
    font-weight: bold;
    @include fluid(font-size, 12px);
    @include fluid(line-height, 14px);
    @include fluid(margin-bottom, 7px);
    display: block;
  }
  textarea {
    @include fluid(padding, 15px);
    @include fluid(min-height, 200px);
  }
  &.checkbox {
    label {
      font-size: 16px;
      line-height: 19px;
      text-transform: none;
      font-weight: $font-weight-base;
      padding-top: 3px;
      padding-left: 38px;
      position: relative;
      margin-bottom: 40px;
      a {
        color: $black;
        font-weight: $font-weight-bolder;
        text-decoration: underline;
      }
    }
    input[type=checkbox] {
      opacity: 0;
      height: 1px;
      width: 1px;
      pointer-events: none;
      position: absolute;

      @include fluid(top, 11px);
      @include fluid(left, 11px);
    }
  }
}
.custom-checkbox {
  width: 22px;
  height: 22px;
  border: 1px solid $colorBorder;
  display: inline-block;
  margin-right: 15px;
  position: absolute;
  top: 0;
  left: 0;
}
input[type=checkbox]:checked + .custom-checkbox {
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: $color1;
  }
}

.form-columns--twocols {
  display: grid;
  grid-template-columns: repeat(2, auto);
  @include fluid(column-gap, 30px);
}
.form-columns--twocols-address {
  grid-template-columns: 33fr 66fr;
}
.form-element--small {

}
.form-element--medium {

}
.form-column {
  .booking-numbers {

    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
  }
  .form-element.booking-element--numbers {
    display: block;
    /*@include fluid(padding-right, 30px);
    @include fluid(padding-left, 30px);*/
    margin: 0;
    padding-bottom: 0;
    label {
      @include fluid(line-height, 14px);
      @include fluid(margin-bottom, 7px);
    }
  }
}

@include media-breakpoint-up(md) {
  .inquiry-container {
    margin-bottom: 100px;
  }
  .inquiry-columns {
    column-count: 2;
    column-gap: 100px;
    break-inside: avoid;
  }
  .form--button-container {
    text-align: center;
    margin-bottom: 100px;
    .btn {
      cursor: pointer;
    }
  }
  .contentblock--form {
    .container--narrow {
      max-width: map-get($grid-breakpoints, lgl);
    }
  }
}

/* Error Message */

.error-message {
  border: 1px solid red;
  background: rgba(red, .2);
  @include fluid(padding, 20px);
  text-align: center;
  @include fluid(margin-bottom, 40px);
}
