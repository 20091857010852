@include media-breakpoint-down(sm) {

  .page-header--logo {
    position: absolute;
    top: pxToVwMobile(50px);
    padding-top: pxToVwMobile(15px);
    z-index: 5;
    width: pxToVwMobile(140px);
    left: 50%;
    transform: translateX(-50%);
    .logo {
      width: 100%;
    }
  }

  .page-header--image {
    position: relative;
  }

  .page-header--widgets {
    width: pxToVwMobile(305px);
    margin: pxToVwMobile(-30px) auto 0;
  }

  .page-header--cta {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    top: calc(#{$headerHeightMobile} - #{pxToVwMobile(59px)});
    transform: translateY(-100%);
  }

  .page-header--boxed {
  }

}


@include media-breakpoint-up(md) {
  .page-header--boxed {
    width: 100%;
    grid-area: logo-nav-widgets;
    @include fluid(padding-left, 30px);
    @include fluid(padding-right, 30px);
    @include fluid(padding-top, 27px);
    padding-bottom: 98px;
    display: grid;
    grid-template-areas:
            'logo nav'
            '. widgets';
    grid-template-columns: 197px auto;
    grid-template-rows: 80px auto;
  }
  .page-header--logo {
    .logo {
      width: 100%;
    }
  }
  .page-header--widgets {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .page-header--nav {
    text-align: right;
  }
}