/* The project may be customized here. */


.page-header--widgets {
    position: relative !important;
}
header .page-header--image {
    background: #7F8429 !important;
}

html {
    max-width: 100% !important;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
}

h2 {
    white-space: pre-wrap;
}

.main-nav--first-level .desktop-down-hidden:last-child .btn {
	background: #7f8429;
	border-color: #7f8429;
}
.main-nav--fixed .main-nav ul.main-nav--first-level .desktop-down-hidden:last-child .btn {
	color: white !important;
}

#tarteaucitronAlertBig {
display: block;
max-height: 50vh;
overflow: scroll;
    overflow-x: scroll;
overflow-x: hidden;


}


#tarteaucitronPersonalize {
	margin-bottom: 10px !important;
}